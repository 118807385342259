import ReactDOM from 'react-dom'
import { App } from './App'
import WebFont from 'webfontloader'
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700', 'sans-serif', 'Material-Icons'],
  },
})

ReactDOM.render(<App />, document.getElementById('root'))
