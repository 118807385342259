import React, { ReactElement, FC } from 'react'
import { Box, Button } from '@mui/material'
import GridLayout from 'react-grid-layout'
import './home.css'

const ReactGridLayout: FC = (): ReactElement => {
  const layout = [
    { i: 'a', x: 0, y: 0, w: 1, h: 2 },
    { i: 'b', x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 },
    { i: 'c', x: 4, y: 0, w: 1, h: 2 },
  ]
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: 'whitesmoke',
        display: 'flex',
      }}
    >
      <GridLayout
        className="layout"
        layout={layout}
        cols={12}
        rowHeight={200}
        width={1200}
      >
        <div style={{ backgroundColor: 'lightblue' }} key="a">
          <Button variant="contained">I am Box A</Button>
        </div>
        <div style={{ backgroundColor: 'lightgrey' }} key="b">
          <Button variant="contained">I am Box B</Button>
        </div>
        <div style={{ backgroundColor: 'lightgreen' }} key="c">
          <Button variant="contained">I am Box C</Button>
        </div>
      </GridLayout>
    </Box>
  )
}

export default ReactGridLayout
