// pages
import Home from './pages/Home'
import Products from './pages/Products'
import ReactGridLayout from './pages/ReactGridLayout'

// other
import { FC } from 'react'

// interface
interface Route {
  key: string
  title: string
  path: string
  enabled: boolean
  component: FC<Record<string, unknown>>
}

export const routes: Array<Route> = [
  {
    key: 'home-route',
    title: 'Home',
    path: '/',
    enabled: true,
    component: Home,
  },
  {
    key: 'products-route',
    title: 'Products',
    path: '/products',
    enabled: true,
    component: Products,
  },
  {
    key: 'react-grid-layout',
    title: 'React Grid Layout',
    path: '/rgl',
    enabled: true,
    component: ReactGridLayout,
  },
]
