import React, { ReactElement, FC } from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'

const Products: FC<any> = (): ReactElement => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: 'whitesmoke',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <Typography variant="h3">Products</Typography>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4} />
        <Grid item xs={4}>
          <Button variant="contained">Click Me</Button>
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </Box>
  )
}

export default Products
